<template>
  <div class="right-section" id="pending-approval">
    <p class="title-section mb-0">Pending For Approval</p>

    <!-- Tabs -->
    <div class="text-center mt-2">
      <input class="tab"
             id="images-tab"
             name="tab"
             type="radio"
             value="images"
             v-model="pendingTab"
             checked />
      <label class="tab-label"
             for="images-tab">
        Images
      </label>

      <input class="tab"
             id="videos-tab"
             name="tab"
             type="radio"
             value="videos"
             v-model="pendingTab" />
      <label class="tab-label"
             for="videos-tab">
        Videos
      </label>
    </div>
    
    <div class="body-section">
      <div class="body-wrapper">
        <!-- Tab Content -->
        <transition name="fade" mode="out-in">
          <pending-images v-if="pendingTab === 'images'"
                          @update-parent-image="updateParentImage">
          </pending-images>

          <pending-videos v-if="pendingTab === 'videos'"
                          @update-parent-video="updateParentVideo">
          </pending-videos>

        </transition>
      </div>
    </div>

  </div>
</template>

<script>
  const PendingImages = () =>
  import('@/components/user/classroom/PendingImages.vue');
  const PendingVideos = () =>
  import('@/components/user/classroom/PendingVideos.vue');
  import { mapActions } from 'vuex';

  export default {
    name : 'PendingApproval',
    data() {
      return {
        pendingTab : 'images',
      }
    },
    components : {
      PendingImages,
      PendingVideos,
    },
    methods : {
      ...mapActions({
        'getIssueCategories' : 'contentReporting/getIssueCategories',
      }),
          
      /**
       * Update function emitted from 'PendingImages.vue'
       */
      updateParentImage() {
        this.$emit('update-images');
      },

      /**
       * Update function emitted from 'PendingVideos.vue'
       */
      updateParentVideo() {
        this.$emit('update-videos');
      },
    },
    created() {
      this.getIssueCategories();
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/components/user/classroom/pending-approval';
</style>
